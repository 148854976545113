import { Fullscreen } from '../fullscreen/fullscreen';
import React from 'react';
import styled from 'styled-components';
import { Background } from '../background/background';
import { Col, Container, Row } from 'reactstrap';
import { PRIMARY_TEXT_COLOR, TITLE_FONT } from '../../constants';

const CaseContainer = styled(Container)`
    z-index: 1;
`;

const CaseTitle = styled.h1`
    font-family: ${TITLE_FONT};
    font-size: 58px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: -3.8px;
    color: ${PRIMARY_TEXT_COLOR};
    width: 370px;

    @media (max-width: 768px) {
        font-size: 32px;
        letter-spacing: -2.1px;
        width: 50vw;
    }
`;

export const CaseHeading = ({ study }) => {
    return (
        <Fullscreen height="84vh" className="d-flex justify-content-center flex-column position-relative">
            <Background color={study.color} bg={study.image} design={'contain'} index={1} position={'60% 100%'} />
            <CaseContainer>
                <Row>
                    <Col>
                        <CaseTitle>{study.headline}</CaseTitle>
                    </Col>
                </Row>
            </CaseContainer>
        </Fullscreen>
    );
};
