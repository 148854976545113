import React from 'react';
import Layout from '../../components/layout/layout';

import { CASE_STUDIES, PRIMARY_TEXT_COLOR } from '../../constants';
import { CaseHeading } from '../../components/case-study/case-heading';
import { Wrapper } from '../../components/shared/wrapper';
import { Container, Row, Col } from 'reactstrap';
import { TextBlock } from '../../components/shared/text-block';
import { Fullscreen } from '../../components/fullscreen/fullscreen';
import { Background } from '../../components/background/background';

import moshi_landscape from '../../../static/images/case-studies/moshi/moshi.png';
import moshi_awards from '../../../static/images/case-studies/moshi/awards.png';
import moshi_3 from '../../../static/images/case-studies/moshi/moshi_3.png';

import press_bbc from '../../../static/images/case-studies/moshi/press/bbc.png';
import press_mab from '../../../static/images/case-studies/moshi/press/mother-and-baby.png';
import press_morning from '../../../static/images/case-studies/moshi/press/this-morning.png';
import press_sun from '../../../static/images/case-studies/moshi/press/the-sun-logo.png';
import press_heart from '../../../static/images/case-studies/moshi/press/the-heart-network-logo-svg.png';

import { CenteredBlock } from '../../components/case-study/centered-block';
import { ListedImages } from '../../components/case-study/listed-images';
import { Link } from 'gatsby';

const MOSHI = CASE_STUDIES.find(study => study.name === 'Moshi twilight.');
const OTHER_SUTDIES = CASE_STUDIES.filter(study => study.name !== 'Moshi twilight.');

export default () => (
    <Layout>
        <CaseHeading study={MOSHI} />
        <Wrapper color={PRIMARY_TEXT_COLOR}>
            <Container>
                <TextBlock title="Bio.">
                    <h2>Mind Candy needed to quickly find a partner.</h2>
                    <p>
                        Mind Candy, a British entertainment company, was looking for native iOS development support for their Moshi Twilight
                        app. Moshi Twilight is the latest product from the global kids entertainment sensation Moshi Monsters. The app plays
                        audio stories and relaxations with music that help kids fall asleep quickly, improving the bedtime experience both
                        for children and their parents.
                    </p>
                    <p>
                        Mind Candy needed to quickly find a reliable and professional development partner given that their app was due to be
                        released on app stores within a very short period of time. They were looking for someone who would be able to jump
                        on-board quickly and work hands-on with their team to create and complete a build for launch, and then work on-going
                        to continually add features and functionality to improve the product.
                    </p>
                </TextBlock>
                <TextBlock title="Our Solution.">
                    <h2>No.1 Trending Family App on Google Play.</h2>
                    <p>
                        We plugged the gap in their native iOS development resources and our collaboration resulted in the successful
                        delivery and publishing of the Moshi Twilight app - on time and on budget. The app has since been featured heavily
                        on the App Store and has been the No.1 Trending Family App on Google Play.
                    </p>
                </TextBlock>
            </Container>
        </Wrapper>
        <Wrapper color={PRIMARY_TEXT_COLOR}>
            <Container>
                <Row className="d-flex justify-content-center flex-row">
                    <Col xs={9}>
                        <Fullscreen height="30vh" className="d-flex justify-content-center flex-column position-relative">
                            <Background bg={moshi_landscape} size={'contain'} index={1} position={'top'} color={PRIMARY_TEXT_COLOR} />
                        </Fullscreen>
                    </Col>
                </Row>
            </Container>
        </Wrapper>
        <Wrapper color={'#2b2b75'}>
            <Container>
                <CenteredBlock title={'Awards.'} txtcolor={PRIMARY_TEXT_COLOR}>
                    <img src={moshi_awards} alt={'Moshi won many awards'} />
                </CenteredBlock>
            </Container>
        </Wrapper>
        <Wrapper color={PRIMARY_TEXT_COLOR}>
            <Container>
                <ListedImages title={'Press and media.'} images={[press_morning, press_mab, press_bbc, press_sun, press_heart]} />
            </Container>
        </Wrapper>
        <Wrapper color={'#a8b8e7'}>
            <Fullscreen height="100vh" className="d-flex justify-content-end flex-column position-relative">
                <Background bg={moshi_3} design={'contain'} index={1} color={'#a8b8e7'} />
            </Fullscreen>
        </Wrapper>
        <Wrapper color={PRIMARY_TEXT_COLOR}>
            <Container>
                <TextBlock title={'Other case studies'}>
                    {OTHER_SUTDIES.map(item => {
                        return (
                            <Link to={item.more}>
                                <h4>{item.name}</h4>
                                {item.title.map(title => {
                                    return <p className="font-weight-light">{title}</p>;
                                })}
                            </Link>
                        );
                    })}
                    <Link to={'/case-studies'}>
                        <h4>More</h4>
                    </Link>
                </TextBlock>
            </Container>
        </Wrapper>
    </Layout>
);
