import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import { PRIMARY_BG_COLOR, TEXT_FONT, TITLE_FONT } from '../../constants';
import { OverlinedSubTitle } from '../shared';

const TextCol = styled(Col)`
    font-family: ${TEXT_FONT};
    font-size: 24px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: 0.2px;
    color: ${props => (props.txtcolor ? props.txtcolor : PRIMARY_BG_COLOR)};

    @media (max-width: 992px) {
        font-size: 22px;

        h2 {
            font-size: 48px;
        }
    }

    p {
        margin-bottom: 50px;
    }

    h2 {
        font-family: ${TEXT_FONT};
        font-size: 48px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: 1.15;
        letter-spacing: 0.3px;
        margin-bottom: 50px;
    }

    h3 {
        font-family: ${TEXT_FONT};
        letter-spacing: -1.8px;
        font-size: 28px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        color: #e8e8e8;

        b {
            font-family: ${TITLE_FONT};
            letter-spacing: -1.7px;
        }
    }

    img {
        object-fit: contain;
        max-width: 100%;
        max-height: 100%;
        width: auto;
        height: auto;
    }
`;

const SpacedRow = styled(Row)`
    margin-bottom: 50px;
    margin-top: 30px;
`;

export const CenteredBlock = ({ title, children, txtcolor }) => {
    return (
        <SpacedRow>
            <Col xs={9} md={3} xl={3}>
                <OverlinedSubTitle txtcolor={txtcolor}>{title}</OverlinedSubTitle>
            </Col>
            <TextCol xs={12} className="d-flex flex-row justify-content-center" txtcolor={txtcolor}>
                {children}
            </TextCol>
        </SpacedRow>
    );
};
