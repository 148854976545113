import { Col, Row } from 'reactstrap';
import React from 'react';
import styled from 'styled-components';
import { OverlinedSubTitle } from '../shared';

const SpacedRow = styled(Row)`
    margin-bottom: 50px;
    margin-top: 30px;
`;

const ImageCol = styled(Col)`
    height: 200px;
    margin-top: 30px;

    img {
        width: 100%;
    }

    @media (max-width: 768px) {
        height: fit-content;
    }
`;

export const ListedImages = ({ title, images, txtcolor }) => {
    return (
        <>
            <SpacedRow>
                <Col xs={9} md={3} xl={3}>
                    <OverlinedSubTitle txtcolor={txtcolor}>{title}</OverlinedSubTitle>
                </Col>
            </SpacedRow>
            <SpacedRow>
                {images.map(image => {
                    return (
                        <ImageCol xs={5} md={3} xl={2}>
                            <img src={image} alt={''} />
                        </ImageCol>
                    );
                })}
            </SpacedRow>
        </>
    );
};
